import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import Swal from "sweetalert2";
import getLocalData from "../../../shared/localStorage/getData";

import Detail from "../../../assets/images/detail-img.jpg";
import Visa from "../../../assets/images/visa.png";
import Paypal from "../../../assets/images/paypal.png";
import "../Products/ProductListing.scss";
import CryptoJS from "crypto-js";

const AddToCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const [cartitem, setCartitem] = useState(cart);
  const [userid, setUserid] = useState();
  const [username, setUsername] = useState();
  const [validateCartStatus, setValidateCartStatus] = useState();
  const [validateCartErrors, setValidateCartStatusErrors] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const secretKey = "G545TV5VT453424C3DE2ED32E32ED23D5WE";

  useEffect(() => {
    if (getLocalData("user_info", "isLogin")) {
      let userData = getLocalData("user_info", "all").userData.login_user_detail
        .data.user;
      setUserid(userData.id);
      setUsername(userData.user_firstname + ' ' + userData.user_lastname);
    }

    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);

  const encryptOrderId = (orderId) => {
    return CryptoJS.AES.encrypt(orderId, secretKey).toString();
  };

  const removeFromCart = (itemId) => {
    const updatedCart = cart.filter((item) => item.inv_id !== itemId);
    setCart(updatedCart);
    setCartitem(updatedCart);
    setValidateCartStatus();
    setValidateCartStatusErrors();
  };

  const updateQuantity = (itemId, action) => {
    const updatedCart = cart.map((item) => {
      if (item.inv_id === itemId) {
        let updatedQuantity = item.quantity;

        if (action === "increment") {
          updatedQuantity += 1;
        } else if (action === "decrement" && updatedQuantity > 1) {
          updatedQuantity -= 1;
        }

        return { ...item, quantity: updatedQuantity };
      }
      return item;
    });

    setCart(updatedCart);
    setCartitem(updatedCart);
    setValidateCartStatus();
    setValidateCartStatusErrors();
  };

  const goToProductPage = () => {
    navigate("/productListing");
  };

  const handleProductClick = (id) => {
    navigate(`/productDetail/${id}`);
  };

  const validateCart = async () => {
    try {
      setIsLoading(true);
      const validatedData = await combinedServices.validateCart(cartitem);
      const allValid = validatedData.data.data.every(
        (item) => item.isValid === true
      );

      if (allValid) {
        setValidateCartStatus(validatedData.data.data);

        const totalAmount = cart
          .reduce((total, item) => total + item.price * item.quantity, 0)
          .toFixed(2);

        const saveorderData = {
          userId: userid,
          userName: username,
          items: cartitem
        };
        try {
          const createOrder = await combinedServices.createOrderinTable(
            saveorderData
          );
          const order_id = createOrder.data.data[0].order_id;
          const encryptedOrderId = encryptOrderId(order_id);

          localStorage.setItem("temp_orderId", encryptedOrderId);

          const orderData = {
            items: cartitem,
            total: totalAmount,
            orderId: encryptedOrderId
          };
          const apiResponse = await combinedServices.shoppingCartPaypalpayment(
            orderData
          );
          if (apiResponse.data.status === 200) {
            const approvalUrl = apiResponse.data.data.approvalUrl;
            if (approvalUrl) {
              window.location.href = approvalUrl;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Order Failed",
              text: "There was an error creating your order."
            });
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error creating order:", error);
          Swal.fire({
            icon: "error",
            title: "Order Failed",
            text: "An unexpected error occurred while processing your order."
          });
          setIsLoading(false);
        }
      } else {
        const invalidItems = validatedData.data.data.filter(
          (item) => item.isValid === false
        );

        setValidateCartStatusErrors(invalidItems);
        const errorMessages = invalidItems
          .map(
            (item) =>
              `<li><strong>${item.product_name}:</strong> ${item.message}</li> <br>`
          )
          .join("");

        Swal.fire({
          icon: "warning",
          title: "Cart Issue",
          html: `<ul style="list-style-type: none; padding-left: 0;">${errorMessages}</ul>`,
          showCloseButton: true,
          focusConfirm: false
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error validating cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error validating the cart."
      });
      setIsLoading(false);

    }
  };

  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="mainCont">
          <section className="ContactOuter pt-5 pb-5">
            <div className="container">
              <div>
                <div className="mb-3 productHd">
                  <span>YOUR CART</span>
                </div>
                {isLoading && (
                  <div className="loadingOverlay">
                    <div className="loadingSpinner"></div>
                  </div>
                )}

                {cart.length > 0 ? (
                  <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-7 cartInfo">
                      {cart.map((item) => (
                        <div
                          key={item.id}
                          className="inner p-3 p-sm-4 d-flex flex-wrap"
                        >
                          <div
                            className="img"
                            onClick={() => handleProductClick(item.id)}
                          >
                            <img
                              src={
                                item.image ? JSON.parse(item.image)[0] : Detail
                              }
                              alt={item.name}
                            />
                          </div>
                          <div className="det ps-s ps-sm-4 pt-3 pt-sm-0">
                            <div className="name mb-3">{item.name}</div>
                            <div className="price mb-4">
                              ${item.price.toFixed(2)} USD{" "}
                              <span className="oldPrice d-block mt-1">
                                ${item.price.toFixed(2)} USD
                              </span>
                            </div>
                            {/* Display attributes in a single line */}
                            <div className="attributes mb-4">
                              <div className="attributesList">
                                {Object.keys(item.attributes).map(
                                  (key, index) => (
                                    <span key={index} className="attributeItem">
                                      <strong>{key}:</strong>{" "}
                                      {item.attributes[key]}
                                      {index <
                                        Object.keys(item.attributes).length -
                                          1 && ", "}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>

                            <div className="cartActions d-flex align-items-center mb-4">
                              {/* Quantity Control */}
                              <div className="quantityControl d-flex align-items-center">
                                <button
                                  className="quantityBtn"
                                  onClick={() =>
                                    updateQuantity(item.inv_id, "decrement")
                                  }
                                >
                                  -
                                </button>
                                <span className="quantityDisplay mx-2">
                                  {item.quantity}
                                </span>
                                <button
                                  className="quantityBtn"
                                  onClick={() =>
                                    updateQuantity(item.inv_id, "increment")
                                  }
                                >
                                  +
                                </button>
                              </div>
                              {/* Remove Item */}
                              <a
                                href="#"
                                className="removeLink"
                                onClick={() => removeFromCart(item.inv_id)}
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="col-12 col-lg-5 payOption pt-4 pt-lg-0 ps-0 ps-lg-5">
                      {/* Payment section */}
                      <div className="hd mb-3">How you'll pay</div>
                      <div className="mb-4">
                        <label className="d-flex align-items-center mt-2">
                          <span className="radioWrapper">
                            <input
                              type="radio"
                              name="payment"
                              className="radioInpt"
                              checked
                            />
                            <i className="bg"></i>
                          </span>{" "}
                          <img src={Paypal} alt="" className="ms-3" />
                          <img src={Visa} alt="" className="ms-3" />
                        </label>
                      </div>
                      <div>
                        <ul className="payDetail p-0 m-0">
                          <li className="d-flex justify-content-between pb-2">
                            <span>Item(s) total</span>
                            <span>
                              $
                              {cart
                                .reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-3">
                            {/* <span>Shop discount</span>
                            <span>-$ 0.00</span> */}
                          </li>
                          <li className="d-flex justify-content-between pb-2 pt-3 bor">
                            <span>Subtotal</span>
                            <span>
                              $
                              {cart
                                .reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-3">
                            <span>Delivery</span>
                            <span>$ 0.00</span>
                          </li>
                          <li className="d-flex justify-content-between pb-2 pt-3 bor">
                            <span>
                              Total (
                              {cart.reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}{" "}
                              items)
                            </span>
                            <span>
                              $
                              {cart
                                .reduce(
                                  (total, item) =>
                                    total + item.price * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </li>
                        </ul>
                        <div className="mt-4">
                          <a onClick={validateCart} className="checkoutBtn">
                            Proceed to Checkout
                          </a>
                        </div>
                        <div className="mt-4 notetext">
                          Local taxes included (where applicable)
                          <br />* Learn more about additional taxes, duties, and
                          fees that may apply
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="emptyCart">
                    <h2>Your Cart is Empty</h2>
                    <p>
                      Looks like you haven't added anything to your cart yet.
                    </p>
                    <button
                      className="goToProductPageBtn"
                      onClick={goToProductPage}
                    >
                      Go to Product Page
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AddToCart;
