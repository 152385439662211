import React, { useState, useEffect } from "react";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";

const MyOrders = () => {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [userid, setUserid] = useState();

  const totalPages = Math.ceil(totalProducts / limit);

  useEffect(() => {
    if (getLocalData("user_info", "isLogin")) {
      let userData = getLocalData("user_info", "all").userData.login_user_detail
        .data.user;
      setUserid(userData.id);
    }
  }, []);

  useEffect(() => {
    if (userid) {
      const fetchOrders = async () => {
        try {
          const orderResponse = await combinedServices.getOrdersforuser(
            userid,
            page,
            limit,
            searchQuery,
            paymentStatus,
            orderStatus
          );
          if (orderResponse?.data?.data) {
            console.log(orderResponse.data.data);
            setProductsData(orderResponse.data.data || []);
            setTotalProducts(orderResponse.data.total || 0);
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };
      fetchOrders();
    }
  }, [userid, page, limit, searchQuery, paymentStatus, orderStatus]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const handlePaymentStatusChange = (e) => {
    setPaymentStatus(e.target.value);
    setPage(1);
  };

  const handleOrderStatusChange = (e) => {
    setOrderStatus(e.target.value);
    setPage(1);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY HH:mm:ss");
  };

  const getStatusClass = (status, type) => {
    if (type === "payment") {
      return {
        COMPLETED: "status-completed-delivered",
        PENDING: "status-pending",
        FAILED: "status-failed-cancelled",
      }[status] || "status-default";
    }
    if (type === "order") {
      return {
        Delivered: "status-completed-delivered",
        Pending: "status-pending",
        Cancelled: "status-failed-cancelled",
      }[status] || "status-default";
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight myOrderCon">
        <div className="dbMid dbInner">
          <div className="dbMainHd">My Orders</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="filBtnWrp">
                    <label className="label2">Payment Status</label>
                    <select
                      value={paymentStatus}
                      onChange={handlePaymentStatusChange}
                      className="form_ctrl ctrlDrop"
                    >
                      <option value="">All Payment</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="PENDING">Pending</option>
                      <option value="FAILED">Failed</option>
                    </select>
                    <span className="ent"></span>

                    <div className="srchGrp">
                      <label className="label2">Order Status</label>

                      <select
                        value={orderStatus}
                        onChange={handleOrderStatusChange}
                        className="form_ctrl ctrlDrop"
                      >
                        <option value="">All Orders</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Pending">Pending</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <span className="ent"></span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product Name and Order ID"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Order ID</th>
                    <th>Product Name</th>
                    <th>Attributes</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Transaction ID</th>
                    <th>Ordered Date</th>
                  </tr>
                </thead>
                <tbody>
                  {productsData.length > 0 ? (
                    productsData.map((order, index) => {
                      const attributes = JSON.parse(order.attribute || "{}");
                      const productImages = JSON.parse(
                        order.product.images || "[]"
                      );

                      return (
                        <tr key={order.id}>
                          <td>{(page - 1) * limit + index + 1}</td>
                          <td>{order.order_id}</td>
                          <td>
                            <div className="catPara">
                              <img
                                src={productImages[0] || ""}
                                alt={order.product.product_name}
                                className="productImage"
                              />
                              {order.product.product_name}
                            </div>
                          </td>
                          <td>
                            {Object.entries(attributes).map(([key, value]) => (
                              <div key={key}>
                                <strong>{key}:</strong> {value}
                              </div>
                            ))}
                          </td>
                          <td>${order.price}</td>
                          <td>{order.quantity}</td>
                          <td>
                            <span
                              className={`status-badge ${getStatusClass(
                                order.paymentStatus,
                                "payment"
                              )}`}
                            >
                              {order.paymentStatus}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`status-badge ${getStatusClass(
                                order.orderStatus,
                                "order"
                              )}`}
                            >
                              {order.orderStatus}
                            </span>
                          </td>
                          <td>{order.transaction_id || "N/A"}</td>
                          <td>{formatDate(order.orderDate) || "N/A"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No Orders Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyOrders;
