import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import "./SuccessPage.scss";
import combinedServices from "../../../shared/services/user-service";
import CryptoJS from "crypto-js";
import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setCartState = useSetRecoilState(cartState);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const capturePayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      const PayerID = searchParams.get("PayerID");
      const encryptedOrderId = localStorage.getItem("temp_orderId");
      const secretKey = "G545TV5VT453424C3DE2ED32E32ED23D5WE";

      const decryptedOrderId = CryptoJS.AES.decrypt(
        encryptedOrderId,
        secretKey
      ).toString(CryptoJS.enc.Utf8);

      if (!token || !PayerID) {
        console.error("Missing token or PayerID.");
        setPaymentStatus("failed");
        setIsLoading(false);
        return;
      }

      try {
        const response = await combinedServices.capturepayment(
          token,
          PayerID,
          decryptedOrderId
        );

        if (response && response.data && response.data.status === 200) {
          console.log("Payment captured successfully:", response.data);

          setPaymentStatus("success");
          localStorage.removeItem("temp_orderId");
          localStorage.removeItem("cartItems");
          setCartState([]);
        } else {
          console.error("No payment details found or payment failed.");
          setPaymentStatus("failed");
        }
      } catch (error) {
        console.error("Error capturing payment:", error);
        setPaymentStatus("failed");
      } finally {
        setIsLoading(false);
      }
    };

    capturePayment();
  }, [location.search, setCartState]);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleViewOrders = () => {
    navigate("/MyOrders");
  };

  return (
    <>
      <Header />
      <div className="success-page">
        <div className="success-container">
          {isLoading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Processing your payment, please wait...</p>
            </div>
          ) : (
            <>
              <div className="icon-container">
                {paymentStatus === "success" ? (
                  <span className="check-icon success">✔</span>
                ) : (
                  <span className="check-icon failed">✘</span>
                )}
              </div>
              {paymentStatus === "success" ? (
                <>
                  <h1>Payment Successful!</h1>
                  <p>
                    Thank you for your purchase. Your order has been
                    successfully processed.
                  </p>
                  <div className="order-confirmation">
                    <p>We hope to see you again!</p>
                  </div>
                </>
              ) : (
                <>
                  <h1>Payment Failed</h1>
                  <p>
                    Unfortunately, your payment could not be processed. Please
                    try again.
                  </p>
                </>
              )}
              <div className="button-group">
                {paymentStatus === "success" ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={handleViewOrders}
                    >
                      View Orders
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleGoHome}
                    >
                      Go to Home
                    </button>
                  </>
                ) : (
                  <button className="btn btn-secondary" onClick={handleGoHome}>
                    Go to Home
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuccessPage;
