import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import { useRecoilState } from "recoil";
import { cartState } from "../../NambaStore/state/cartState";
import swal from "sweetalert";

import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import "./ProductListing.scss";

import Facebook from "../../../assets/images/facebook.png";
import X from "../../../assets/images/x.png";
import Pinit from "../../../assets/images/pinit.png";

const ProductDetail = () => {
  const { id } = useParams();
  const [productDetailData, setProductDetailData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState([]);
  const [attributeKeys, setAttributeKeys] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [filteredOptions, setFilteredOptions] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useRecoilState(cartState);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const openFullScreen = (index) => {
    setCurrentImageIndex(index);
    setIsImageModalOpen(true);
  };
  
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  useEffect(() => {
    const fetchProductsDetails = async () => {
      try {
        const response = await combinedServices.getProductDetailsforUser(id);
        if (response && response.data && response.data.data) {
          setProductDetailData(response.data.data.product || {});
          setInventoryData(response.data.data.inventory || []);
          console.log(response.data.data.inventory);
          if (response.data.data.inventory.length > 0) {
            setAttributeKeys(
              Object.keys(response.data.data.inventory[0].attribute)
            );
          }
        } else {
          console.error("No product details found.");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductsDetails();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsResponse = await combinedServices.getProductsforUser();
        if (
          productsResponse &&
          productsResponse.data &&
          productsResponse.data.data
        ) {
          setProductsData(productsResponse.data.data || []);
        } else {
          console.error("No products found.");
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const updatedOptions = {};
    attributeKeys.forEach((key, index) => {
      const dependentAttributes = attributeKeys.slice(0, index);
      const filteredData = inventoryData.filter((item) =>
        dependentAttributes.every(
          (depKey) => item.attribute[depKey] === selectedAttributes[depKey]
        )
      );
      updatedOptions[key] = [
        ...new Set(filteredData.map((item) => item.attribute[key]))
      ];
    });

    setFilteredOptions(updatedOptions);
  }, [selectedAttributes, attributeKeys, inventoryData]);

  const handleChange = (key, value) => {
    const updatedAttributes = { ...selectedAttributes, [key]: value };
    const index = attributeKeys.indexOf(key);
    attributeKeys.slice(index + 1).forEach((depKey) => {
      updatedAttributes[depKey] = "";
    });
    setSelectedAttributes(updatedAttributes);
  };

  const allAttributesSelected = () => {
    return (
      attributeKeys.length > 0 &&
      attributeKeys.every(
        (key) =>
          selectedAttributes[key] && selectedAttributes[key].trim() !== ""
      )
    );
  };

  const handleAddToCart = () => {
    if (!allAttributesSelected()) {
      alert("Please select all attributes before adding to the cart.");
      return;
    }
  
    if (!quantity || quantity <= 0) {
      alert("Please enter a valid quantity greater than 0.");
      return;
    }
  
    const matchingInventoryItem = inventoryData.find((item) => {
      return attributeKeys.every(
        (key) => item.attribute[key] === selectedAttributes[key]
      );
    });
  
    if (!matchingInventoryItem) {
      alert("No matching inventory item found for the selected attributes.");
      return;
    }
  
    const item = {
      id: productDetailData.product_id,
      inv_id: matchingInventoryItem.inv_id,
      image: productDetailData.images,
      name: productDetailData.product_name,
      price: productDetailData.cost_price,
      quantity,
      attributes: selectedAttributes
    };
  
    const updatedCart = [...cart];
    const existingItemIndex = updatedCart.findIndex((cartItem) => {
      const isSameId = cartItem.id === item.id;
      const areAttributesEqual =
        JSON.stringify(cartItem.attributes || {}) ===
        JSON.stringify(item.attributes || {});
      return isSameId && areAttributesEqual;
    });
  
    if (existingItemIndex > -1) {
      updatedCart[existingItemIndex] = {
        ...updatedCart[existingItemIndex],
        quantity: updatedCart[existingItemIndex].quantity + quantity
      };
      swal("Success!", "Product updated in cart", "success");
      setSelectedAttributes({});
    } else {
      updatedCart.push(item);
      swal("Success!", "Product added to cart", "success");
      setSelectedAttributes({});
    }
  
    setCart(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };
  

  if (!productDetailData) {
    return <div>Loading...</div>;
  }

  const { product_name, cost_price, description, images, sku_code } =
    productDetailData;

  const imageUrls = images ? JSON.parse(images) : [];
  const handleNextImage = () => {
    if (currentImageIndex < imageUrls.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // const openFullScreen = (index) => {
  //   const fullScreenImage = window.open();
  //   fullScreenImage.document.body.innerHTML = `<img src="${imageUrls[index]}" alt="Full Screen Image" style="width:100%; height:auto;" />`;
  // };

  const handleProductClick = (id) => {
    navigate(`/productDetail/${id}`);
  };

  return (
    <>
      <div className="mainCon">
        <Header />

        <div className="mainCont">
          <section className="ContactOuter pt-5 pb-5">
            <div className="container">
              <div>
                <div className="mb-3 backLink">
                  <Link to="/products">Back to search results</Link>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="col-12 col-sm-6 col-lg-7 detailImg">
                    {/* Image Carousel */}
                    <div className="carousel">
                      <button
                        className="carousel-btn prev"
                        onClick={handlePrevImage}
                      >
                        ❮
                      </button>
                      <img
                        src={
                          imageUrls[currentImageIndex] || "/default-image.jpg"
                        }
                        alt={`Product image ${currentImageIndex + 1}`}
                        onClick={() => openFullScreen(currentImageIndex)} // Open in full screen on click
                      />
                      <button
                        className="carousel-btn next"
                        onClick={handleNextImage}
                      >
                        ❯
                      </button>
                      <div className="image-index">
                        {currentImageIndex + 1} / {imageUrls.length}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-5 detailInfo pt-4 pt-sm-0 ps-0 ps-sm-3 ps-lg-5">
                    <div className="name">
                      <span className="d-block mb-2">{product_name}</span> SKU:{" "}
                      {sku_code}
                    </div>
                    <div className="price mt-3">${cost_price} USD</div>
                    <div className="quantity mt-4">
                      Quantity:{" "}
                      <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        defaultValue="1"
                      />
                    </div>
                    <div className="mt-4 d-flex flex-wrap">
                      {attributeKeys.map((key, index) => (
                        <div key={index} className="attribute-dropdown col-4 pe-2 pb-2">
                          <label className="d-block mb-1">{key}</label>
                          <select className="formSelect"
                            value={selectedAttributes[key] || ""}
                            onChange={(e) => handleChange(key, e.target.value)}
                          >
                            <option value="">Select {key}</option>
                            {filteredOptions[key]?.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                    <div className="addCart mt-4">
                      <button
                        type="button"
                        onClick={handleAddToCart}
                        disabled={!allAttributesSelected()}
                      >
                        Add to Cart
                      </button>
                    </div>
                    <div className="saveForLater mt-4">
                      <span className="d-block mb-3">
                        Save this product for later
                      </span>
                      <button className="favorite-btn">
                        <span className="material-icons">favorite_border</span>{" "}
                        Favorite
                      </button>
                    </div>
                    {/* <div className="mt-5 share">
                      <span className="d-block mb-3">
                        Share this product with your friends
                      </span>
                      <ul className="d-flex m-0 p-0">
                        <li className="me-3">
                          <a href="#">
                            <img src={Facebook} alt="Facebook" />
                          </a>
                        </li>
                        <li className="me-3">
                          <a href="#">
                            <img src={X} alt="Twitter/X" />
                          </a>
                        </li>
                        <li className="me-3">
                          <a href="#">
                            <img src={Pinit} alt="Pinterest" />
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="pt-5 desText">
                  <h2>Product Details</h2>
                  <p>{description}</p>
                </div>
              </div>

              <div className="pt-5">
                <h2 className="productHd mb-3">You May Also Like</h2>
                <ul className="d-flex flex-wrap productList p-0">
                  {productsData && productsData.length > 0 ? (
                    productsData.map((product, index) => {
                      const images = JSON.parse(product.images || "[]");
                      const isOutOfStock = product.outOfStock === true;

                      return (
                        <li
                          className="col-6 col-md-3"
                          key={index}
                          onClick={() =>
                            !isOutOfStock &&
                            handleProductClick(product.product_id)
                          }
                          style={{
                            cursor: isOutOfStock ? "not-allowed" : "pointer",
                            opacity: isOutOfStock ? 0.4 : 1
                          }}
                        >
                          <div className="inner">
                            <div className="img">
                              {images.length > 0 ? (
                                <img
                                  src={images[0]}
                                  alt={`Product Image`}
                                  className="product-image"
                                />
                              ) : (
                                <img
                                  src="https://via.placeholder.com/150"
                                  alt="Default Placeholder"
                                />
                              )}
                            </div>
                            <div
                              className="name mt-3"
                              style={{
                                color: isOutOfStock ? "red" : "initial",
                                opacity: isOutOfStock ? 1 : 1
                              }}
                            >
                              {product.product_name || "Product Name"}{" "}
                              {isOutOfStock && <span>(Out of Stock)</span>}
                              <span className="d-block pt-1">
                                $
                                {product.retail_price
                                  ? product.retail_price.toFixed(2)
                                  : "0.00"}{" "}
                                USD
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li>No products available</li>
                  )}
                </ul>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
      {isImageModalOpen && (
  <div className="image-modal">
    <div className="image-modal-content">
      <button className="close-button" onClick={closeImageModal}>
        ✖
      </button>
      <div className="image-container">
        <img
          src={imageUrls[currentImageIndex]}
          alt="Full Screen Image"
          className="modal-image"
        />
      </div>
      <div className="image-navigation">
        <button
          className="prev-button"
          onClick={() =>
            setCurrentImageIndex(
              currentImageIndex > 0 ? currentImageIndex - 1 : imageUrls.length - 1
            )
          }
        >
          ❮
        </button>
        <button
          className="next-button"
          onClick={() =>
            setCurrentImageIndex(
              currentImageIndex < imageUrls.length - 1 ? currentImageIndex + 1 : 0
            )
          }
        >
          ❯
        </button>
      </div>
    </div>
  </div>
)}

    </>
  );
};

export default ProductDetail;