import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import getRemoveData from "../../../shared/localStorage/removeData";

const Header = () => {
    const [isActive, setActive] = useState(false);
    const [isStoreOpen, setStoreOpen] = useState(true);
    const db_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png";

    const toggleClass = () => {
        setActive(!isActive);
    };

    const handleLogout = () => {
        getRemoveData("admin_info");
        localStorage.clear();
        window.location.href = "/login";
    };

    const toggleStoreMenu = () => {
        setStoreOpen(!isStoreOpen);
    };

    const handleSubmenuClick = (e) => {
        e.stopPropagation(); 
    };

    return (
        <>
            <a
                className={isActive ? 'showDBMenu active' : 'showDBMenu'}
                onClick={toggleClass}
            >
                <span><i></i><i></i><i></i></span>
            </a>
            <div className={isActive ? 'dbAdminLeft active' : 'dbAdminLeft'}>
                <div className="dbLogo">
                    <NavLink to="/"><img src={db_logo} alt="" /></NavLink>
                </div>
                <div className="dbAdminMenu">
                    <ul>
                        <li><NavLink to="/admin-dashboard">SMS Template</NavLink></li>
                        <li><NavLink to="/email-template">Email Template</NavLink></li>
                        <li><NavLink to="/home-content">Home Content</NavLink></li>
                        <li><NavLink to="/payment-gateway">Paypal Credential</NavLink></li>
                        <li><NavLink to="/audios">Audios</NavLink></li>
                        <li>
                            <a
                                href="#"
                                className={`d-flex justify-content-between align-items-center ${
                                    isStoreOpen ? "active" : ""
                                }`}
                                onClick={toggleStoreMenu}
                            >
                                Store <span className="material-icons">expand_more</span>
                            </a>
                            {/* Add dynamic class for toggling submenu */}
                            <ul
                                className={`submenu ${isStoreOpen ? "open" : ""}`}
                                onClick={handleSubmenuClick}  // Prevent closing when clicking submenu items
                            >
                                <li><NavLink to="/catagorylist">Catagory list</NavLink></li>
                                <li><NavLink to="/attributeList">Attribute list</NavLink></li>
                                <li><NavLink to="/productlist">Product List</NavLink></li>
                                <li><NavLink to="/InventoryList">Inventory List</NavLink></li>
                                <li><NavLink to="/orderList">Order List</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to="#" onClick={handleLogout}>Logout</NavLink></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Header;
