import React, { useState, useEffect } from "react";
import combinedServices from "../../../shared/services/user-service";
import { useNavigate } from "react-router-dom";

import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import "./ProductListing.scss";
import Event1 from "../../../assets/images/event-img1.jpg";

const ProductListing = () => {
  const [productsData, setProductsData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let productsResponse = await combinedServices.getProductsforUser();
        if (productsResponse && productsResponse.data) {
          setProductsData(productsResponse.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        let eventsResponse = await combinedServices.getEventsforusers();
        if (eventsResponse && eventsResponse.data) {
          setEventData(eventsResponse.data.events || []);
        }
      } catch (error) {
        console.error("Error fetching Events:", error);
      }
    };
    fetchEvents();
  }, []);

  const handleProductClick = (id) => {
    navigate(`/productDetail/${id}`);
  };

  const handleEnterEvent = (id) => {
    navigate("../eventTicket/" + id);
  };

  const myOrders = () => {
    navigate(`/MyOrders`);
  };

  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="mainCont">
          <section className="ContactOuter pt-5 pb-5">
            <div className="container">
              <div className="d-block d-sm-flex flex-wrap justify-content-between align-items-end pb-5">
                <div>
                  <div className="mb-3 productHd">
                    <span className="me-1">BOAT RACE</span> 1,000+ RELEVANT
                    RESULTS
                  </div>
                  <div className="d-flex">
                    <select className="formSelect small me-3">
                      <option>Price ($)</option>
                    </select>
                    <select className="formSelect small">
                      <option>All Sellers</option>
                    </select>
                  </div>
                </div>

                <div className="mt-3 mt-sm-0">
                  <div className="filBtnWrp">
                    <button className="nambaBtn" onClick={()=>myOrders()}>My Orders</button>
                  </div>
                  <select className="formSelect small">
                    <option>Sort by: Relevance</option>
                  </select>
                </div>
              </div>

              <div className="mb-5">
                <div className="productHd mb-3">
                  <span>TICKET AND EVENTS</span>
                </div>
                <ul className="d-flex flex-wrap p-0 productList">
                  {eventData.map((event, index) => {
                    return (
                      <li
                        className="col-6 col-md-3"
                        key={index}
                        onClick={() => handleEnterEvent(event.id)}
                      >
                        <div className="inner">
                          <div className="img">
                            <img
                              src={event.image || Event1}
                              alt={event.full_title || `Event ${index + 1}`}
                            />
                          </div>
                          <div className="name mt-3">
                            {event.full_title || "Event Title"}{" "}
                            <span className="d-block pt-1">
                              $
                              {parseFloat(
                                event.registration_charges || "0.00"
                              ).toFixed(2)}{" "}
                              USD ({event.event_status})
                            </span>
                            <span className="d-block">
                              {new Date(event.event_start).toLocaleDateString()}{" "}
                              - {new Date(event.event_end).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div>
                <div className="productHd mb-3">
                  <span>PRODUCTS</span>
                </div>
                <ul className="d-flex flex-wrap productList p-0">
                  {productsData.map((product, index) => {
                    const images = JSON.parse(product.images || "[]");
                    const isOutOfStock = product.totalQuantity === "0";

                    return (
                      <li
                        className="col-6 col-md-3"
                        key={index}
                        onClick={() =>
                          !isOutOfStock &&
                          handleProductClick(product.product_id)
                        }
                        style={{
                          cursor: isOutOfStock ? "not-allowed" : "pointer",
                          opacity: isOutOfStock ? 0.4 : 1
                        }}
                      >
                        <div className="inner">
                          <div className="img">
                            {images.length > 0 ? (
                              <img
                                src={images[0]}
                                alt={product.product_name || "Product"}
                              />
                            ) : (
                              <img
                                src="https://via.placeholder.com/150"
                                alt="Default Placeholder"
                              />
                            )}
                          </div>
                          <div
                            className="name mt-3"
                            style={{
                              color: isOutOfStock ? "red" : "initial",
                              opacity: isOutOfStock ? 1 : 1
                            }}
                          >
                            {product.product_name || "Product Name"}{" "}
                            {isOutOfStock && <span>(Out of Stock)</span>}
                            <span className="d-block pt-1">
                              $
                              {product.retail_price
                                ? product.retail_price.toFixed(2)
                                : "0.00"}{" "}
                              USD
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductListing;
