import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import userConstants from "../../shared/constants/user-constants";
import swal from "sweetalert";
import AddStep3 from "./AddEventStep3";
import EditJoinRace from "./UpdateEventStep1";
import { Spinner } from "reactstrap";
import getRemoveData from "../../shared/localStorage/removeData";
import getLocalData from "../../shared/localStorage/getData";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { lightGreen } from "@mui/material/colors";
import EditableForm from "./editTransponders";

const AddEvent = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [eventId, setEventId] = useState("");
  const [eventDetails, setEventDetails] = useState([]);
  const [eventClassDetails, setEventClassDetails] = useState([]);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentSelectedMethod, setSelectedPaymentMethod] = useState("");
  const [classIds, setClassIds] = useState([]);
  const [total, setTotal] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [handicappedPitSpace, setHandicappedPitSpace] = useState(false);
  const [hostClubName, setHostClubName] = useState("");
  const [subMember, setSubMember] = useState([]);
  const [selectedSubMemberId, setSelectedSubMemberId] = useState(0);
  const [selectedSubMember, setSelectedSubMember] = useState("");
  const [selectedSubMemberAndClass, setSelectedSubMemberAndClass] = useState(
    []
  );
  const [selectedSubMemberAndClass1, setSelectedSubMemberAndClass1] = useState(
    []
  );
  const [participantIds, setParticipantIds] = useState("");
  const [editEntryRaceButton, setEditEntryRaceButton] = useState(false);
  const [specialNote, setSpecialNote] = useState("");
  const [searchUser, setSearchUser] = useState([]);
  const [successPage, setSuccessPage] = useState(false);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState("");
  const [participantId, setParticipateId] = useState([]);
  const [maxEnteries, setMaxEnteries] = useState(0);
  const [userID, setUserId] = useState("");
  const [authoriseduser, setAuthorisedUser] = useState([]);
  const [contestdir, setContestDir] = useState("");
  const [addEntr, setAddEntr] = useState(false);
  const [transponders, setTransponders] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editopen, setEditOpen] = React.useState(false);
  const [disabledall, setDisabledAll] = React.useState(true);

  const [selectedTransponder, setSelectedTransponder] = useState([]);
  const [enable, setEnable] = useState(true);
  const [inputbox, setInputbox] = useState(false);
  const [classinput, setClassinput] = useState(false);
  const [transponderAllow, setTransponderallow] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);

  const [userName, setUserName] = useState("");
  const [isLoder, setIsLoder] = useState(false);

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;

      setUserId(userId);
    }
    var ele = document.getElementById("toggleText");
    ele.style.display = "none";

    var paymentArr = [];
    setEventId(params.id);
    let eventResponse = await combinedServices.getEventDetail(params.id);
    if (eventResponse.status == 401) {
      swal(eventResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    let eventDetail = eventResponse.data;
    var event_contact = eventDetail.event_contacts.split(",");

    var data = {
      authorizatized: eventDetail.event_authorized_user_ids,
      event_updatedBy: eventDetail.event_updated_by,
    };
    setAuthorisedUser(data.authorizatized);
    setContestDir(data.event_updatedBy);
    // setEventDetails(data);
    if (
      userId === data.event_updatedBy ||
      data.authorizatized.includes(userId)
    ) {
      setAddEntr(true);
    }

    let eventClassResponse = await combinedServices.getClassByEvent(params.id);
    if (eventClassResponse.status == 401) {
      swal(eventClassResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    //let eventSubMember = await combinedServices.getAllSubMemberList(localStorage.getItem("parent_token"));
    let eventSubMember = await combinedServices.getAllUserForManageEntries();
    if (eventSubMember.status == 401) {
      swal(eventSubMember.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    if (eventSubMember.status == 200) {
      //setSubMember(eventSubMember.data);
      var userLIst = [];
      eventSubMember.data.map((user) => {
        if (user.user_status === "active") {
          var data = {
            name: user.user_firstname + " " + user.user_lastname,
            id: user.id,
          };

          userLIst.push(data);
        }
      });

      setSubMember(userLIst);
    }

    if (eventClassResponse.data.event.allow_mail_ins) {
      paymentArr.push("Payment By Mail");
    }
    if (eventClassResponse.data.event.allow_pay_at_race_entries) {
      paymentArr.push("Payment At Race");
    }
    if (eventClassResponse.data.event.allow_paypal_entries) {
      paymentArr.push("PayPal");
    }
    setPaymentMethod(paymentArr);

    setHostClubName(eventClassResponse.data.event.host_club.club_name);
    console.log("eventclassresponse", eventClassResponse);
    setEventDetails(eventClassResponse.data.event);
    setMaxEnteries(eventClassResponse.data.event.max_enteries);
    setTransponderallow(eventClassResponse.data.event.transponder_allow);
    const sortedClasses = eventClassResponse.data.eventClasses.sort((a, b) => {
      if (a.class_Class_Name < b.class_Class_Name) return -1;
      if (a.class_Class_Name > b.class_Class_Name) return 1;
      return 0;
    });
    setEventClassDetails(sortedClasses);
    // const res = await combinedServices.getTransponders({ event_id: params.id });
    // setTransponders(res.data);
  }, []);

  const handleRegistionCost = (e, class_id, class_name, index) => {
    if (class_id && inputbox === true && transponderAllow == 1) {
      setClassinput(true);
    }
    var newClassArry = [...classIds];

    var total = registrationCount;

    if (e.target.checked) {
      var data = {
        id: class_id,
        name: class_name,
        index,
        // transponder_id:transponderId,
        // transponder_name:transponderName
      };
      newClassArry.push(data);

      setClassIds(newClassArry);
      total = total + 1;

      const totalCost =
        Number(eventDetails.registration_charge) +
        Number(eventDetails.boat_charge * total);

      setTotal(totalCost.toFixed(2));

      setRegistrationCount(total);
    } else {
      var index = newClassArry.indexOf(class_id); // Let's say it's Bob.
      newClassArry.splice(index, 1);
      setClassIds(newClassArry);

      total = total - 1;

      if (total == 0) {
        setTotal("");
        setRegistrationCount(0);
      } else {
        const totalCost =
          Number(eventDetails.registration_charge) +
          Number(eventDetails.boat_charge * (total - 1));
        setTotal(totalCost.toFixed(2));

        setRegistrationCount(total);
      }
    }
  };

  const handleTransponderChange = (e, index) => {
    const newTransponder = [...selectedTransponder];
    const selectedDetails = JSON.parse(e.target.value);

    const data = { ...selectedDetails, index };
    // Check if the index is within the valid range
    let existingIndex = newTransponder.findIndex(
      (item) => item.index === data.index
    );
    if (existingIndex !== -1) {
      // Update existing object
      newTransponder[existingIndex] = data;
    } else {
      // Push new object
      newTransponder.push(data);
    }
    // if (index >= 0 && index < newTransponder.length) {
    //   // Replace the object at the specified index with the new object
    //   newTransponder[index] = data;
    // } else {
    //   // Add the new transponder to the array if the index is out of range
    //   newTransponder.push(data);
    // }

    // Update the state
    setSelectedTransponder(newTransponder);

    // Assuming transponderAllow and selectedTransponder are defined
    if (selectedTransponder.length > 0 && transponderAllow === 1) {
      setClassinput(false);
    }
  };

  const calculateDaysBetweenTwoDate = () => {
    var future = moment(eventDetails.registration_close);
    var start = moment(eventDetails.registration_open);
    var d = future.diff(start, "days") + 1; // 9

    return d;
  };

  const handleSubUser = async (user) => {
    if (user != "") {
      setDisabledAll(false);
    }
    setInputbox(true);
    setSelectedSubMemberId(user.id);
    setUserName(user.name);

    document.getElementById("searchUser").value = user.name;
    var ele = document.getElementById("toggleText");
    ele.style.display = "none";

    var registeredData = {
      event_id: eventId,
      user_id: user.id,
    };

    let registeredUser = await combinedServices.checkIfUserJoined(
      registeredData
    );
    setUserId(registeredData.user_id);
    let response = await combinedServices.getTransponders(
      registeredData.user_id
    );
    setTransponders(response.data.data);

    if (registeredUser.status == 200) {
      setParticipantIds(registeredUser.data.id);
      setEditEntryRaceButton(true);
      //navigate("../event/update-join-event/" + registeredUser.data.id, { replace: true });
      // swal("!Opps", "Member is already registered in this event", "error") ;
      //setEditEntryRaceButton(true) ;
    } else {
      setEditEntryRaceButton(false);
      //setParticipantIds(registeredUser.data.id)

      //var country_select = document.querySelector("#sub_member_name");
      //var user_name = country_select.options[country_select.selectedIndex].getAttribute('user_name');
      setSelectedSubMember(user.name);
    }
  };

  const handleSubUserAndClass = async () => {
    setInputbox(false);
    if (selectedSubMemberId == 0) {
      return swal("!Opps", "Please select member first ", "error");
    }
    /////
    if (transponderAllow == 1) {
      const classIndexes = classIds.map(({ index }) => index);

      // Get an array of unique transponder indexes
      const transponderIndexes = selectedTransponder.map(({ index }) => index);
      //  var allClassIndexesInTransponders=false;
      // Check if all class indexes are present in transponder indexes
      const allClassIndexesInTransponders = classIndexes.every((classIndex) =>
        transponderIndexes.includes(classIndex)
      );

      if (allClassIndexesInTransponders === true) {
        // Mapping selectedTransponder array to the desired format while checking indexes

        const resultArray = selectedTransponder
          .filter(({ index }) => classIndexes.includes(index))
          .map(({ index, id, transponder_name }) => ({
            class_id: classIds.find((classObj) => classObj.index === index).id,
            transponder_id: id,
            transponder_name,
          }));
        setClassIds(resultArray);

        var newArr = [...selectedSubMemberAndClass];
        setEditEntryRaceButton(false);
        var data = {
          user_name: selectedSubMember,
          selectedClass: resultArray,
          user_id: Number(selectedSubMemberId),
          classWiseTotal: total,
          pit_space: Boolean(handicappedPitSpace),
          special_note: specialNote,
        };

        newArr.push(data);

        const data2 = [data];

        setSelectedSubMemberAndClass(newArr);

        var inputs = document.querySelectorAll(".checkInpt");
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        setClassIds([]);
        setSelectedTransponder([]);
        setTotal(0);
        setSelectedSubMember("");
        setSelectedSubMemberId(0);
        setRegistrationCount(0);
        setHandicappedPitSpace(false);
        setTransponders([]);
        document.getElementById("searchUser").value = "";
      } else {
        return swal("!Opps", "Please select Transponder ", "error");
      }
    } else {
      var newArr = [...selectedSubMemberAndClass];
      setEditEntryRaceButton(false);
      var data = {
        user_name: selectedSubMember,
        selectedClass: classIds,
        user_id: Number(selectedSubMemberId),
        classWiseTotal: total,
        pit_space: Boolean(handicappedPitSpace),
        special_note: specialNote,
      };

      newArr.push(data);
      const data2 = [data];

      setSelectedSubMemberAndClass(newArr);
      const classIDMap = {};
      for (const classEntry of eventClassDetails) {
        classIDMap[classEntry.class_Class_Name_ID] = classEntry;
      }

      // Iterate through the users array
      for (const user of data2) {
        const selectedClasses = user.selectedClass;

        for (const selectedClass of selectedClasses) {
          const classID = selectedClass.id;

          // Find the class entry using the mapping
          const classEntry = classIDMap[classID];

          if (classEntry) {
            // Increment the participant count for the class
            classEntry.entriesCount = Number(classEntry.entriesCount) + 1;
          }
        }
      }

      // Print the updated class participant counts
      for (const classEntry of eventClassDetails) {
      }

      var inputs = document.querySelectorAll(".checkInpt");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
      setClassIds([]);
      setTotal(0);
      setSelectedSubMember("");
      setSelectedSubMemberId(0);
      setRegistrationCount(0);
      setHandicappedPitSpace(false);
      setTransponders([]);
    }
  };

  const handleUserMemberDelete = async (id) => {
    let eventClassResponse = await combinedServices.getClassByEvent(params.id);
    let eventClassDetails = eventClassResponse.data.eventClasses;
    eventClassDetails = eventClassDetails.sort((a, b) => {
      if (a.class_Class_Name < b.class_Class_Name) return -1;
      if (a.class_Class_Name > b.class_Class_Name) return 1;
      return 0;
    });
    var arr = selectedSubMemberAndClass.filter(function (item) {
      return item.user_id !== id;
    });

    setSelectedSubMemberAndClass(arr);

    const classIDMap = {};
    for (const classEntry of eventClassDetails) {
      classIDMap[classEntry.class_Class_Name_ID] = classEntry;
    }

    // Iterate through the users array
    for (const user of arr) {
      const selectedClasses = user.selectedClass;

      for (const selectedClass of selectedClasses) {
        const classID = selectedClass.id;

        // Find the class entry using the mapping
        const classEntry = classIDMap[classID];

        if (classEntry) {
          // Increment the participant count for the class
          classEntry.entriesCount = Number(classEntry.entriesCount) + 1;
        }
      }
    }

    setEventClassDetails(eventClassDetails);
    // Print the updated class participant counts
    for (const classEntry of eventClassDetails) {
    }
  };

  const handleEditRace = () => {
    navigate("../my-race/", { replace: true });
  };

  const handleSpecialNotes = (e) => {
    setSpecialNote(e.target.value);
  };

  const handleSearchBox = (e) => {
    setSearchUser(e.target.value);
    var ele = document.getElementById("toggleText");

    if (e.target.value != "") {
      setInputbox(true);
      setDeleteUser(false);
    }
    setClassinput(false);

    ele.style.display = "";
  };

  const handlePaypalChecout = async () => {
    var participantIdsArr = String(participantId)
      .split(",")
      .map((num) => {
        return Number(num);
      });

    if (selectPaymentMethod == "PAYPAL") {
      let eventClassResponse = await combinedServices.addPaypalForm(
        participantIdsArr
      );
      setIsLoder(false)
      if (eventClassResponse.status == 200) {
        setSuccessPage(true);
        window.location.href = eventClassResponse.data.payment_link;
      } else {
        swal("Oops!", eventClassResponse.msg, "error");
      }
    } else if (selectPaymentMethod === "PAY_AT_RACE") {
      var paymentDataArr = [];

      participantIdsArr.map((value) => {
        var data = {
          event_participant_id: value,
          payment_for: "event_participation",
          pit_space: handicappedPitSpace,

          payment_type: "PAY_AT_RACE",
        };

        paymentDataArr.push(data);
      });

      let eventPayAtRace = await combinedServices.addPaymentMany(
        paymentDataArr
      );
      setIsLoder(false)
      if (eventPayAtRace.status == 200) {
        setSuccessPage(true);
        //let emailResponse = await combinedServices.sendEmailForParticipant(value);
        if (addEntr === true) {
          navigate("../manage-entries/" + eventId, { replace: true });
        } else {
          navigate("../event-detail/" + eventId, { replace: true });
        }
      } else {
        swal("Oops!", eventPayAtRace.msg, "error");
      }
    } else if (selectPaymentMethod === "MAIL_IN") {
      var paymentDataArr = [];

      participantIdsArr.map((value) => {
        var data = {
          event_participant_id: value,
          payment_for: "event_participation",
          pit_space: handicappedPitSpace,

          payment_type: "MAIL_IN",
        };

        paymentDataArr.push(data);
      });

      let eventMailsResponse = await combinedServices.addPaymentMany(
        paymentDataArr
      );
      if (eventMailsResponse.status == 200) {
        setSuccessPage(true);
        navigate("../payment-success?event_id=" + eventId, { replace: true });
      } else {
        swal("Oops!", eventMailsResponse.msg, "error");
      }
    }
  };
  const handlePaymentMethod = async (e) => {
    console.log("heehiri");
    var newMemberArr = [];
    selectedSubMemberAndClass.map((memberData) => {
      var data = {
        event_id: eventDetails.id,
        pit_space: memberData.pit_space,
      };
      var classes = [];
      data.user_id = Number(memberData.user_id);
      if (transponderAllow == 1) {
        memberData.selectedClass.map((clsVal) => {
          var x = {
            class_id: clsVal.class_id,
            transponder_id: clsVal.transponder_id,
            transponder_name: clsVal.transponder_name,
          };
          classes.push(x);
        });
      } else {
        memberData.selectedClass.map((clsVal) => {
          var x = {
            class_id: clsVal.id,
            // transponder_id: clsVal.transponder.id,
            // transponder_name: clsVal.transponder.name,
          };
          classes.push(x);
        });
      }
      data.classIds = classes;
      data.payment_type = " ";

      data.special_note = memberData.special_note;
      newMemberArr.push(data);
    });
    let eventClassResponse = await combinedServices.addEventParticipateMany(
      newMemberArr
    );
    console.log("eeeeeee", eventClassResponse);
    setParticipateId(eventClassResponse.data);

    ///////
    var paymentArr = [];
    let eventClassResponse1 = await combinedServices.getEventParticipateClass(
      eventClassResponse.data
    );
    console.log("eeeeeeee", eventClassResponse1);
    if (eventClassResponse1.data.event.allow_mail_ins) {
      paymentArr.push("Payment By Mail");
    }
    if (eventClassResponse1.data.event.allow_pay_at_race_entries) {
      paymentArr.push("Payment At Race");
    }
    if (eventClassResponse1.data.event.allow_paypal_entries) {
      paymentArr.push("PayPal");
    }
    setPaymentMethod(paymentArr);
    setEventDetails(eventClassResponse1.data.event);
    //setPaymentMethod(eventClassResponse.data.participantsData[0].eventParticipant.payment_type)
    setEventId(eventClassResponse1.data.event.id);

    // setEventClassDetails1(eventClassResponse1.data.participantsData);

    var payment_type = "";

    switch (e.target.value) {
      case "Payment At Race":
        // code block
        payment_type = "PAY_AT_RACE";
        break;
      case "Payment By Mail":
        // code block
        payment_type = "MAIL_IN";
        break;
      case "PayPal":
        // code block
        payment_type = "PAYPAL";
        break;
      default:
        payment_type = "";
    }

    if (eventClassResponse1.data.participantsData) {
      eventClassResponse1.data.participantsData.map(async (value) => {
        var eventParticipantData = value.eventParticipant;
        var data = {
          id: eventParticipantData.id,
          user_id: eventParticipantData.user_id,
          payment_type: payment_type,
        };

        let paymentTypeResponse = await combinedServices.updatePaymentType(
          data
        );
        if (paymentTypeResponse.status == 200) {
          setSelectPaymentMethod(payment_type);
        }
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleclickopen1 = () => {
    setEditOpen(true);
  };

  const handleClose2 = async () => {
    setEditOpen(false);
    let response = await combinedServices.getTransponders(userID);
    setTransponders(response.data.data);
  };
  return (
    <>
      <div className="mainCon">
        <Header />

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
                <a href={`/manage-entries/${eventId}`}>Manage Enteries</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              <div className="addEventTop flexBox spacebetween itemCenter">
                <div className="left flexBox itemCenter nowrap">
                  <img
                    src={
                      eventDetails?.image?.startsWith("https://nambaweb")
                        ? eventDetails.image
                        : baseURL + "images/" == eventDetails.image
                        ? "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
                        : eventDetails.image
                    }
                    alt=""
                  />
                  {eventDetails.full_title}
                  <br />
                  {hostClubName}
                </div>

                <div className="right">
                  Registration In -{" "}
                  {moment(eventDetails.registration_open).format("MMM Do")} -{" "}
                  {moment(eventDetails.registration_close).format("MMM Do")}
                  {calculateDaysBetweenTwoDate() > 0
                    ? "," + calculateDaysBetweenTwoDate() + " days"
                    : ""}
                  <br />
                </div>
              </div>

              <form id="myForm">
                <div className="addEventMid">
                  <div className="paymentMetHd">Members INFORMATION</div>
                  <div className="eventInner">
                    <div className="lft">Members</div>
                    <div className="rgt">
                      <div className="eventInner searchBox membersList-box">
                        <input
                          type="text"
                          className="searchUser"
                          id="searchUser"
                          placeholder="search user"
                          onChange={handleSearchBox}
                          autoComplete="off"
                        />

                        <ul className="flexBox eventForm" id="toggleText">
                          {subMember
                            .filter((val) => {
                              if (searchUser == "") {
                                return val;
                              } else if (
                                val.name
                                  .toLowerCase()
                                  .includes(searchUser.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .map((user) => (
                              <li onClick={() => handleSubUser(user)}>
                                {" "}
                                {user.name}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {editEntryRaceButton ? (
                  <EditJoinRace participateId={participantIds} />
                ) : (
                  <div className="addEventMid">
                    <div className="addEventTable">
                      <table>
                        <tr>
                          <th>CLASS</th>
                          {transponderAllow == 1 ? (
                            <th>TRANSPONDER</th>
                          ) : (
                            <th></th>
                          )}
                          <th>ENTRIES COUNT </th>
                        </tr>
                        {eventClassDetails.map((distVal, index) => {
                          const isMaxEntriesReached =
                            distVal.entriesCount == maxEnteries;
                          if (maxEnteries > 0) {
                            return (
                              <tr index={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="checkInpt"
                                    onChange={(e) =>
                                      handleRegistionCost(
                                        e,
                                        distVal.class_Class_Name_ID,
                                        distVal.class_Class_Name,
                                        index
                                      )
                                    }
                                    disabled={
                                      isMaxEntriesReached
                                      //  ||
                                      // selectedSubMemberId === 0
                                    }
                                  />
                                  {distVal.class_Class_Name}
                                  {/* {distVal.class_Class_Name_ID} */}
                                </td>

                                {transponderAllow == 1 ? (
                                  <td>
                                    <div
                                      className="smllInput"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="listLeft"
                                        style={{
                                          width: "70%",
                                          textAlign: "initial",
                                        }}
                                      >
                                        <ul
                                          // className="flexBox eventForm"
                                          disabled={selectedSubMemberId === 0}
                                        >
                                          <li>
                                            {/* <div className="rgt"> */}
                                            <select
                                              className="formSelect"
                                              id="sub_member_name"
                                              onChange={(e) =>
                                                handleTransponderChange(
                                                  e,
                                                  index
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Transponder
                                              </option>
                                              {transponders.length > 0
                                                ? transponders.map((value) => {
                                                    return (
                                                      <option
                                                        key={value.id}
                                                        value={JSON.stringify(
                                                          value
                                                        )}
                                                        disabled={
                                                          value.user_status ==
                                                          "inactive"
                                                            ? "disabled"
                                                            : ""
                                                        }
                                                        user_name={
                                                          value.user_firstname +
                                                          " " +
                                                          value.user_lastname
                                                        }
                                                      >
                                                        {value.transponder_name +
                                                          " - " +
                                                          "(" +
                                                          value.id +
                                                          ")"}{" "}
                                                      </option>
                                                    );
                                                  })
                                                : ""}
                                            </select>
                                            {/* </div> */}
                                          </li>
                                        </ul>
                                        {/* </div> */}
                                      </div>
                                      <div
                                        style={{
                                          width: "30%",
                                          paddingRight: "5px",
                                          textAlign: "initial",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleclickopen1}
                                        >
                                          +
                                        </Button>

                                        <Dialog
                                          open={editopen}
                                          onClose={handleClose2}
                                        >
                                          <DialogTitle>
                                            Transponders
                                            <IconButton aria-label="delete">
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </DialogTitle>
                                          <DialogContent>
                                            <EditableForm
                                              data={selectedSubMemberId}
                                            />
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={handleClose2}>
                                              Save
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td></td>
                                )}

                                <td>
                                  {distVal.entriesCount
                                    ? distVal.entriesCount + "/" + maxEnteries
                                    : 0 + "/" + maxEnteries}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr index={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="checkInpt"
                                    name="checkInpt"
                                    onChange={(e) =>
                                      handleRegistionCost(
                                        e,
                                        distVal.class_Class_Name_ID,
                                        distVal.class_Class_Name,
                                        index
                                      )
                                    }
                                    // disabled={
                                    //   selectedSubMemberId===0
                                    // }
                                  />

                                  {distVal.class_Class_Name}
                                </td>
                                {transponderAllow == 1 ? (
                                  <td>
                                    <div
                                      className="smllInput"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="listLeft"
                                        style={{
                                          width: "70%",
                                          textAlign: "initial",
                                        }}
                                      >
                                        {/* <Box>
                                          <FormControl fullWidth>
                                            <InputLabel id="transponder-dropdown-label">
                                              Select Transponder
                                            </InputLabel>
                                            <Select
                                              labelId="transponder-dropdown-label"
                                              id="transponder-dropdown"
                                              onChange={(e) =>
                                                handleTransponderChange(
                                                  e,
                                                  index
                                                )
                                              }
                                             
                                            >
                                              {transponders?.map((item) => (
                                                <MenuItem
                                                  key={item.id}
                                                  value={item}
                                                >
                                                  {item.transponder_name}
                                               
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box> */}
                                        {/* <div className="eventInner"> */}
                                        <ul
                                          // className="flexBox eventForm"
                                          disabled={selectedSubMemberId === 0}
                                        >
                                          <li>
                                            <select
                                              className="formSelect"
                                              id="sub_member_name"
                                              onChange={(e) =>
                                                handleTransponderChange(
                                                  e,
                                                  index
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Transponder
                                              </option>
                                              {transponders?.length > 0
                                                ? transponders?.map((value) => {
                                                    return (
                                                      <option
                                                        key={value.id}
                                                        value={JSON.stringify(
                                                          value
                                                        )}
                                                        disabled={
                                                          value.user_status ==
                                                          "inactive"
                                                            ? "disabled"
                                                            : ""
                                                        }
                                                        user_name={
                                                          value.user_firstname +
                                                          " " +
                                                          value.user_lastname
                                                        }
                                                      >
                                                        {value.transponder_name +
                                                          " - " +
                                                          "(" +
                                                          value.id +
                                                          ")"}{" "}
                                                      </option>
                                                    );
                                                  })
                                                : ""}
                                            </select>
                                          </li>
                                        </ul>
                                        {/* </div> */}
                                      </div>
                                      <div
                                        style={{
                                          width: "30%",
                                          paddingRight: "5px",
                                          textAlign: "initial",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleclickopen1}
                                        >
                                          +
                                        </Button>

                                        <Dialog
                                          open={editopen}
                                          onClose={handleClose2}
                                        >
                                          <DialogTitle>
                                            Transponders
                                          </DialogTitle>
                                          <DialogContent>
                                            <EditableForm
                                              data={selectedSubMemberId}
                                            />
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={handleClose2}>
                                              Save
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>
                                  {distVal.entriesCount
                                    ? distVal.entriesCount
                                    : 0}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        <tr className="paymentMetHd">
                          <td>Total</td>
                          <td></td>

                          <td>{total}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}
              </form>

              {!editEntryRaceButton && (
                <>
                  <div className="eventDetBot">
                    <div className="paymentMetHd">Special Note BY Member</div>
                    <div className="eventInner">
                      <ul className="flexBox eventForm">
                        <li className="full">
                          <textarea
                            rows="4"
                            cols="12"
                            className="formInput"
                            name="contacts"
                            onChange={handleSpecialNotes}
                          ></textarea>
                          <div className="exText">
                            Please enter only one contact name and number per
                            line.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {selectedSubMemberAndClass.length > 0 && (
                    <>
                      <div className="eventDetBot">
                        <div className="paymentMetHd">
                          Members WITH CLASSES INFORMATION
                        </div>
                        <ul className="flexBox cartList">
                          {selectedSubMemberAndClass.map((value, index) => {
                            return (
                              <li>
                                <div className="inner">
                                  <span
                                    className="material-icons close"
                                    onClick={() =>
                                      handleUserMemberDelete(value.user_id)
                                    }
                                  >
                                    close
                                  </span>
                                  <div className="hd">{value.user_name}</div>
                                  <div className="acctable">
                                    <table>
                                      {transponderAllow != 1
                                        ? value.selectedClass.map(
                                            (classes, classIndex) => (
                                              <tr key={classIndex}>
                                                <td>{classes.name}</td>
                                              </tr>
                                            )
                                          )
                                        : value.selectedClass.map(
                                            (classes, classIndex) => {
                                              const classId = classes.class_id;

                                              const classDetails =
                                                eventClassDetails.find(
                                                  (detail) =>
                                                    detail.class_Class_Name_ID ===
                                                    classId
                                                );

                                              if (classDetails) {
                                                return (
                                                  <tr key={classIndex}>
                                                    <td>
                                                      {
                                                        classDetails.class_Class_Name
                                                      }
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            }
                                          )}
                                    </table>
                                  </div>

                                  <div className="specialNote">
                                    <strong>Special Note: </strong>
                                    {value.special_note}
                                  </div>
                                  <div className="total">
                                    <div>Total</div>
                                    <div>{value.classWiseTotal}</div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="addEventBot">
                    <div className="paymentType">
                      <div className="addEventBot">
                        {selectedSubMemberAndClass.length > 0 ? (
                          <>
                            <button
                              type="button"
                              className="continueBtn"
                              onClick={handleSubUserAndClass}
                              // disabled={!selectedSubMemberId}
                            >
                              Add Another Entry
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="continueBtn"
                              onClick={handleSubUserAndClass}
                              // disabled={!selectedSubMemberId}
                            >
                              Add The Entry
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {selectedSubMemberAndClass.length > 0 && (
                    // <div className="addEventBot">
                    //   <div className="paymentType">
                    //     <button
                    //       type="button"
                    //       className="continueBtn"
                    //       onClick={handleStep1Form}
                    //     >
                    //       Checkout1
                    //     </button>
                    //   </div>
                    // </div>
                    <>
                      <div className="addEventBot">
                        <div className="paymentType">
                          <div className="addEventBot">
                            <div className="paymentMetHd">PAYMENT METHOD </div>
                            <div className="paymentType">
                              <span>Select payment method:</span>
                              <select
                                className="formSelect"
                                name="payment"
                                onChange={handlePaymentMethod}
                              >
                                <option>Select</option>
                                {paymentMethod.length > 0
                                  ? paymentMethod.map((payValue, index) => {
                                      return (
                                        <option value={payValue}>
                                          {payValue}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="addEventBot">
                        <div className="paymentType pad0">
                          {isLoder ? (
                            <div className="text-center mt-4 loadMore">
                              <Spinner color="primary" />
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="continueBtn"
                              onClick={handlePaypalChecout}
                            >
                              Complete Entry
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default AddEvent;
